<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn :to="{name: 'CabinetIndex'}" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>Произведения</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        :to="{name: 'CabinetBookAdd'}"
        text
        tile
      >{{ $vuetify.breakpoint.smAndUp ? 'Добавить произведение' : 'Добавить' }}</v-btn>
    </v-toolbar>
    <v-container class="drag-container" v-if="!loadPage">
      <v-btn color="primary" :to="{name: 'CabinetBookAdd'}" dark fixed bottom right fab>
        <v-icon>$vuetify.icons.plus</v-icon>
      </v-btn>
      <v-row v-if="books.length == 0" align="center">
        <no-content route-name="CabinetBookAdd" title="У вас еще нет произведений"></no-content>
      </v-row>
      <div v-if="books.length > 0" class="draggable">
        <draggable
          animation="400"
          handle=".drag-handle"
          v-model="books"
          tag="div"
          class="row"
          @change="orderBooksUpdate"
        >
          <book-card
            v-for="(book, index) in books"
            :key="book.id"
            :book="book"
            :index="index"
            :cabinet="true"
            @remove-book-modal="deleteBookModal"
            :admin="true"
           :library="false"
          ></book-card>
        </draggable>
      </div>

      <v-dialog v-model="dialogDeleteBook" class="text-center" max-width="290">
        <v-card>
          <v-card-title class="headline justify-center">Вы уверены?</v-card-title>

          <v-card-text class="text-center">Что хотите удалить данную запись.</v-card-text>

          <v-card-actions class="justify-space-around">
            <v-btn color="red darken-1" text @click="deleteBook">Да</v-btn>

            <v-btn color="green darken-1" text @click="dialogDeleteBook = false">Нет</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BookCard from "../book/BookCard.vue";
import draggable from "vuedraggable";
export default {
  components: {
    BookCard,
    draggable,
  },
  data: () => ({
    dialogDeleteBook: false,
    deleteData: null,
  }),
  methods: {
    getBooks() {
      this.$store.dispatch("cabinet_books/getBooks");
    },
    deleteBook() {
      let app = this;
      app.dialogDeleteBook = false;
      this.$store.dispatch("cabinet_books/dellBook", {
        deleteData: app.deleteData,
      });
    },
    deleteBookModal(data) {
      this.deleteData = data;
      this.dialogDeleteBook = true;
    },
    orderBooksUpdate() {
      this.books.map((item, index) => {
        item.order = index + 1;
        index = item.order;
      });
      //event.preventDefault();
      this.loading = true;
      let app = this;
      this.$store.dispatch("cabinet_books/updateBooksOrder", {
        books: app.books,
      });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      // books: "cabinet_books/books"
    }),
    books: {
      get() {
        return this.$store.getters["cabinet_books/books"];
      },
      set(value) {
        this.$store.commit("cabinet_books/GET_BOOKS", value);
      },
    },
  },
  mounted() {
    this.getBooks();
  },
};
</script>
